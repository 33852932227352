<script setup lang="ts">
import { apiClient } from "@netgame/openapi";
import { toast } from "vue3-toastify";

import type { Promotion } from "@/types";

const props = withDefaults(
	defineProps<{
		promotions: Array<ReturnType<ReturnType<typeof createDecorator>>>;
		hideLabel?: boolean;
		hideInSoonMode?: boolean;
		view?: "grid" | "slider";
	}>(),
	{ view: "grid" }
);

defineEmits<{ (event: "refreshData"): void }>();

const { t } = useT();
const { open } = useFunrizeModals();
const { copy } = useClipboard();
const { isDesktop } = useDevice();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { refresh: refreshBonusesData } = useGetBonusesData();
const { isLimitOffer } = useFlipTheCoin();
const { prizeDropsData } = usePrizeDrop();
const { isSweepStakes } = useSwitchMode();
const loginGuard = useLoginGuard();
const { data: appInit } = useAppInitData();
const isGuest = useIsGuest();
const { dailyWheelData, getMaxWinByWheel } = useWheels();
const { data: tournamentData } = useGetTournamentData();
const { handleSubscribe } = useTournamentSubscribe({
	id: tournamentData.value?.data?.id,
	isSubscribed: tournamentData.value?.data?.isSubscribed
});
const { questData, questPrizeSum, isAdventQuest } = useGameQuest();
const { depositInfoData, durationLeft, durationLeftResetStreak } = useFunrizeDepositStreakData();

const needToWaitDailyWheel = computed(
	() => !dailyWheelData.value?.wheelAvailable && dailyWheelData.value?.dailyWheel?.reasonType === "time"
);

const promotionsList = computed(() =>
	props.promotions?.filter((promo) => promo.type !== "flipTheCoin" || !isLimitOffer.value)
);

const isActiveDepositStreak = computed(() => appInit.value?.depositStreak?.isActive);

const isHideHoverSlide = (type = "") =>
	computed(
		() => (type === "depositStreak" && !isActiveDepositStreak.value) || (type === "quest" && !isAdventQuest.value)
	);

const openDeposit = (promotion: Promotion) => {
	let url = "";

	if (promotion.data?.package) {
		url = promotion.data.package.promoOfferPreset
			? `/cash/deposit-by-money/${promotion.data.package.promoOfferPreset.id}/promoOffer/`
			: `/cash/deposit-by-money/${promotion.data.package.preset?.id}/preset/?inviteQualificationMoney=${promotion.data.package.money}`;
	} else {
		url = "/cash/deposit-by-money/";
	}

	window?.$cash?.$router?.push?.(url);
};

const openVerificationBonus = async (promotion: Promotion) => {
	await window?.$cash?.$store?.dispatch?.("cash/getAppCash");

	const { fraudDetector, profileCompleted } = (window?.$cash?.$store?.getters["cash/appCash"] as {
		fraudDetector: { enabled: boolean; verificationCompleted: boolean };
		profileCompleted: boolean;
	}) ?? {
		fraudDetector: { enabled: false, verificationCompleted: false },
		profileCompleted: false
	};

	if (
		fraudDetector?.enabled &&
		profileCompleted &&
		!fraudDetector?.verificationCompleted &&
		!localStorage.getItem("isFraudDetectorRunning")
	) {
		open("LazyOModalVerificationBonus", { entries: promotion.data?.bonusEntries });
		await apiClient({
			path: "/rest/fraud-detectors/identity-match/",
			method: "post",
			parameters: { query: { action: "click_profile" } }
		});
		localStorage.setItem("isFraudDetectorRunning", "start");
		return;
	}

	if (fraudDetector?.verificationCompleted) {
		window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		return;
	}

	if (profileCompleted) {
		open("LazyOModalVerificationBonus", { entries: promotion.data?.bonusEntries });
		return;
	}
	window?.$cash?.$router?.push?.("/cash/account/?isFraudDetector=true&isOutsideCash=true");
};

const handleFlipTheCoinClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalFlipTheCoin");
			refreshBonusesData();
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

const handleClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			if (promotion.type === "dailyWheel") {
				open("LazyOModalWheelDaily", { location: "promotions" });
				dispatchGAEvent({
					event: "click_button",
					location: "promotions",
					button_name: "spin_win"
				});
			} else if (promotion.type === "verification") {
				navigateTo(preparedLink("game", "/#verify"));
			} else if (promotion.type === "invite") {
				dispatchGAEvent({ event: "click_button", location: "promotions", button_name: "copy" });
				copy(promotion.data?.referralUrl || "");

				toast.success(t("Link copied."), {
					containerId: "promo-cards",
					icon: h("i", { class: "toast-icon icon-checked" }),
					theme: toast.THEME.DARK,
					position: toast.POSITION.BOTTOM_CENTER,
					transition: toast.TRANSITIONS.SLIDE,
					autoClose: 3000
				});
			} else if (promotion.type === "inviteQualification") {
				openDeposit(promotion);
			} else if (promotion.type === "partnerReferralQualification") {
				window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
			} else if (promotion.type === "partnerReferralQualificationByVerification") {
				openVerificationBonus(promotion);
			} else {
				dispatchGAEvent({ event: "click_button", location: "promotions", buttonName: "make_deposit" });
				window?.$store?.gaCash?.deposit?.({
					location: "promotions",
					step: "view_payments_window",
					activePackage: promotion.data?.money
				});

				window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${promotion?.data?.promoOfferPreset?.id}/promoOffer/`);
			}
		},
		fail: () => open("LazyOModalSignup")
	});
};
const handleClickRushHour = () => {
	loginGuard({
		success: () => {
			dispatchGAEvent({
				event: "deposit",
				location: "promotions",
				step: "view_payments_info"
			});
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => open("LazyOModalSignup")
	});
};
const handleClickSeason = () => {
	if (appInit.value?.season?.isAvailable) {
		open("LazyOModalSeasonTower");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
const handleTournamentClick = () => {
	loginGuard({
		success: () => {
			if (!tournamentData.value?.data?.isActive) {
				return;
			}

			handleSubscribe(tournamentData.value?.data?.id);
			dispatchGAEvent({
				event: "click_button",
				location: "promotions",
				button_name: tournamentData.value?.data?.isSubscribed ? t("Play now") : t("coming next"),
				form_name: "Right now"
			});
			if (tournamentData.value?.data?.isSubscribed || !isGuest.value) {
				open("LazyOModalRacesGames");
			}
		},
		fail: () => open("LazyOModalSignup", { location: "promotions" })
	});
};

const handleClickAdvent = () => {
	open("LazyOModalAdventCalendar");
};
</script>
<template>
	<div v-if="view === 'grid'" class="row-cards">
		<AAnimationHoverSlide
			v-if="!isGuest && !hideInSoonMode && appInit?.scratchCardLottery?.isActive"
			:active="isDesktop"
		>
			<MPromotionScratchCards />
		</AAnimationHoverSlide>
		<template v-for="(card, index) in promotionsList" :key="index">
			<MPromotionSkeleton v-if="card.skeleton" />
			<AAnimationHoverSlide v-else :active="isDesktop" :class="{ hide: isHideHoverSlide(card?.type).value }">
				<MPromotionBingo v-if="card.type === 'bingo'" />
				<MPromotionRushHour
					v-else-if="card.type === 'rushHourOffers'"
					:available="!!card.data?.available"
					:availableTill="card.data?.availableTill || 0"
					:availableFrom="card.data?.availableFrom || 0"
					:subscriptionStatus="card.data?.subscriptionStatus || ''"
					:badge="parseJSON(card.data?.badgeLabel || '{}')"
					:subscribed="!!card.data?.isSubscribed"
					:presetGroupId="card.data?.presetGroupId || 0"
					:emailConfirmed="!!appInit?.emailConfirmed"
					@click="handleClickRushHour"
					@update-promotion="$emit('refreshData')"
				/>
				<MPromotionPrizeDrops
					v-else-if="card.type === 'prizeDrops'"
					:entries="prizeDropsData?.general?.dailyPrizePool || 0"
					:finished-at="prizeDropsData.general?.nextPrizePoolStartedAt || ''"
				/>
				<MPromotionDepositStreak
					v-else-if="card.type === 'depositStreak' && isActiveDepositStreak"
					:deposit-info="depositInfoData"
					:finished-at="durationLeft"
					:reset-streak-at="durationLeftResetStreak"
				/>
				<MPromotionSeason
					v-else-if="card.type === 'season'"
					:image="`${baseImageUrl}${card.package?.imagePopup}`"
					:subTitle="card.package?.subTitle || ''"
					:title="card.package?.title || ''"
					:coins="card.package?.coins || 0"
					:entries="card.package?.entries || 0"
					@click="handleClickSeason"
				/>
				<MPromotionTournament
					v-else-if="card.type === 'tournament'"
					type="tournament"
					:image="`${baseImageUrl}${card.package?.imagePopup}`"
					:coins="card.package?.coins || 0"
					:entries="card.package?.entries || 0"
					:tournament="tournamentData?.data"
					:sweepStakes="isSweepStakes"
					:subTitle="card.package?.subTitle || ''"
					:title="card.package?.title || ''"
					@click="handleTournamentClick"
				/>
				<MPromotionAdvent
					v-else-if="card.type === 'quest' && isAdventQuest"
					:subTitle="card.package?.subTitle || ''"
					:title="questData?.questInfo?.title || ''"
					:entries="questPrizeSum"
					:finishedAt="questData?.questInfo?.end || ''"
					@click="handleClickAdvent"
				/>
				<MPromotionFlipTheCoin
					v-else-if="card.type === 'flipTheCoin'"
					:image="card.package?.imagePopup || ''"
					type="flipTheCoin"
					:title="card.package?.title || ''"
					:subTitle="card.package?.subTitle || ''"
					@click="handleFlipTheCoinClick"
				/>
				<MPromotionFreeSpin v-else-if="card.type === 'playerFreeSpin' && card.data" :data="card.data" />
				<MPromotionItem
					v-else-if="!['quest', 'depositStreak'].includes(card.type || '')"
					:type="card.type"
					:sweepStakes="isSweepStakes"
					:image="`${baseImageUrl}${card.package?.imagePopup}`"
					:subTitle="card.package?.subTitle || ''"
					:title="card.package?.title || ''"
					:coins="card.package?.coins || 0"
					:entries="card.package?.entries || 0"
					:freeSpinsAmount="card.data?.promoOfferPreset?.freeSpinAmount || 0"
					:wheelMaxWin="getMaxWinByWheel"
					:date="card.date"
					:showAvailable="!!card.data?.promoOfferPreset?.availableTill"
					:enabled="card.data?.enabled"
					:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
					:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
					:rankLeague="!!card.data?.promoOfferPreset?.rankLeague"
					:freeSpin="!!card.data?.promoOfferPreset?.freeSpin"
					:gameId="card.data?.promoOfferPreset?.freeSpinGameId"
					:showBadge="!!card.data?.promoOfferPreset?.badgeLabel"
					:badge="card.badgeLabel"
					:currencySymbol="appInit?.currencySymbol"
					:money="card.data?.money"
					:textTooltip="card.tooltipText"
					:needToWaitDailyWheel="!!needToWaitDailyWheel"
					:activeRushHoursOffers="card.data?.promoOfferPreset?.activeInHours"
					:exclusionType="card.hasExclusionType"
					:baseline="card.data?.baseline || 0"
					:subType="card.data?.promoOfferPreset?.subType || ''"
					:isDepositStreakTicket="
						Number(card.data?.money) >= Number(depositInfoData?.minDepositValue) && isActiveDepositStreak
					"
					@click="handleClick(card)"
				/>
			</AAnimationHoverSlide>
		</template>
	</div>

	<template v-if="view === 'slider'">
		<div
			v-if="!isGuest && !hideInSoonMode && appInit?.scratchCardLottery?.isActive"
			class="keen-slider__slide"
			data-tid="promo-card"
		>
			<AAnimationHoverSlide :active="isDesktop">
				<MPromotionScratchCards />
			</AAnimationHoverSlide>
		</div>
		<div
			v-for="(card, index) in promotionsList"
			:key="`${card.type}-${index}`"
			:class="['keen-slider__slide', { hide: isHideHoverSlide(card?.type).value }]"
			data-tid="promo-card"
		>
			<MPromotionSkeleton v-if="card.skeleton" />
			<AAnimationHoverSlide v-else :active="isDesktop">
				<MPromotionBingo v-if="card.type === 'bingo'" />
				<MPromotionRushHour
					v-else-if="card.type === 'rushHourOffers'"
					:available="!!card.data?.available"
					:availableTill="card.data?.availableTill || 0"
					:availableFrom="card.data?.availableFrom || 0"
					:subscriptionStatus="card.data?.subscriptionStatus || ''"
					:badge="parseJSON(card.data?.badgeLabel || '{}')"
					:subscribed="!!card.data?.isSubscribed"
					:presetGroupId="card.data?.presetGroupId || 0"
					:emailConfirmed="!!appInit?.emailConfirmed"
					@click="handleClickRushHour"
					@update-promotion="$emit('refreshData')"
				/>
				<MPromotionPrizeDrops
					v-else-if="card.type === 'prizeDrops'"
					:entries="prizeDropsData?.general?.dailyPrizePool || 0"
					:finished-at="prizeDropsData.general?.nextPrizePoolStartedAt || ''"
				/>
				<MPromotionDepositStreak
					v-else-if="card.type === 'depositStreak' && isActiveDepositStreak"
					:deposit-info="depositInfoData"
					:finished-at="durationLeft"
					:reset-streak-at="durationLeftResetStreak"
				/>
				<MPromotionSeason
					v-else-if="card.type === 'season'"
					:image="`${baseImageUrl}${card.package?.imagePopup}`"
					:subTitle="card.package?.subTitle || ''"
					:title="card.package?.title || ''"
					:coins="card.package?.coins || 0"
					:entries="card.package?.entries || 0"
					@click="handleClickSeason"
				/>
				<MPromotionTournament
					v-else-if="card.type === 'tournament'"
					type="tournament"
					:image="`${baseImageUrl}${card.package?.imagePopup}`"
					:coins="card.package?.coins || 0"
					:entries="card.package?.entries || 0"
					:tournament="tournamentData?.data"
					:sweepStakes="isSweepStakes"
					:subTitle="card.package?.subTitle || ''"
					:title="card.package?.title || ''"
					@click="handleTournamentClick"
				/>
				<MPromotionAdvent
					v-else-if="card.type === 'quest' && isAdventQuest"
					:subTitle="card.package?.subTitle || ''"
					:title="questData?.questInfo?.title || ''"
					:entries="questPrizeSum"
					:finishedAt="questData?.questInfo?.end || ''"
					@click="handleClickAdvent"
				/>
				<MPromotionFlipTheCoin
					v-else-if="card.type === 'flipTheCoin'"
					:image="card.package?.imagePopup || ''"
					type="flipTheCoin"
					:title="card.package?.title || ''"
					:subTitle="card.package?.subTitle || ''"
					@click="handleFlipTheCoinClick"
				/>
				<MPromotionFreeSpin v-else-if="card.type === 'playerFreeSpin' && card.data" :data="card.data" />
				<MPromotionItem
					v-else-if="!['quest', 'depositStreak'].includes(card.type || '')"
					:hideLabel="hideLabel"
					:type="card.type"
					:sweepStakes="isSweepStakes"
					:image="`${baseImageUrl}${card.package?.imagePopup}`"
					:subTitle="card.package?.subTitle || ''"
					:title="card.package?.title || ''"
					:coins="card.package?.coins || 0"
					:entries="card.package?.entries || 0"
					:freeSpinsAmount="card.data?.promoOfferPreset?.freeSpinAmount || 0"
					:wheelMaxWin="getMaxWinByWheel"
					:date="card.date"
					:showAvailable="!!card.data?.promoOfferPreset?.availableTill"
					:enabled="card.data?.enabled"
					:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
					:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
					:rankLeague="!!card.data?.promoOfferPreset?.rankLeague"
					:freeSpin="!!card.data?.promoOfferPreset?.freeSpin"
					:gameId="card.data?.promoOfferPreset?.freeSpinGameId"
					:showBadge="!!card.data?.promoOfferPreset?.badgeLabel"
					:badge="card.badgeLabel"
					:currencySymbol="appInit?.currencySymbol"
					:money="card.data?.money"
					:textTooltip="card.tooltipText"
					:needToWaitDailyWheel="!!needToWaitDailyWheel"
					:activeRushHoursOffers="card.data?.promoOfferPreset?.activeInHours"
					:exclusionType="card.hasExclusionType"
					:baseline="card.data?.baseline || 0"
					:subType="card.data?.promoOfferPreset?.subType || ''"
					:isDepositStreakTicket="
						Number(card.data?.money) >= Number(depositInfoData?.minDepositValue) && isActiveDepositStreak
					"
					@click="handleClick(card)"
				/>
			</AAnimationHoverSlide>
		</div>
	</template>
</template>

<style scoped lang="scss">
.row-cards {
	display: flex;
	flex-wrap: wrap;
	gap: gutter(2.5);

	@include media-breakpoint-down(lg) {
		gap: gutter(2);
		justify-content: center;
	}

	.hide {
		display: none;
	}
}

.keen-slider__slide {
	&.hide {
		display: none;
	}
}
</style>
